import { FC, useEffect, useState } from 'react';
import { Chart } from '../chart';
import styles from './graphic.module.scss';
import { BuyTokenButtons } from '../buyTokenButtons';
import { useGraphicData } from './graphic.hook';
import { useWeb3React } from '@web3-react/core';
import { useSaleContract } from '../../../../../../hooks/web3/useContract';
import { getBuyBackTime } from '../../../../../../utils/web3/getBuyBackTime';

interface GraphicProps {
  handleOpenBuyModal: () => void;
  handleOpenSellModal: () => void;
}

export const Graphic: FC<GraphicProps> = ({
  handleOpenBuyModal,
  handleOpenSellModal,
}) => {
  const { data, currentPrice } = useGraphicData();
  const [isSellEnabled, setSellEnabled] = useState(false);
  const { account } = useWeb3React();
  const saleContract = useSaleContract();

  useEffect(() => {
    const init = async () => {
      if (!account || !saleContract) {
        return;
      }
      const buyBackTime = await getBuyBackTime(saleContract, account);
      console.log('account', account);
      console.log('date now', Date.now());
      console.log('buyBackTime', buyBackTime * 1000);
      const isEnabled = Date.now() > buyBackTime * 1000;
      setSellEnabled(isEnabled);
      if (isEnabled) {
        return;
      }
      setTimeout(() => {
        setSellEnabled(true);
      }, buyBackTime * 1000 - Date.now());
    };

    init().catch(console.error);
  }, [account, saleContract]);

  return (
    <div className={styles.wrapper}>
      <Chart data={data} />
      <div className={styles.buttonsWrapper}>
        <BuyTokenButtons
          onBuyToken={handleOpenBuyModal}
          onSellToken={handleOpenSellModal}
          tokenPrice={currentPrice}
          isSellEnabled={isSellEnabled}
        />
      </div>
    </div>
  );
};
